import React, { useState } from 'react';
import MainLayout from '../layouts/mainLayout';
import { Button } from 'react-bootstrap';
import { graphql, navigate } from 'gatsby';
import loadable from '@loadable/component';
import { isLoggedIn } from 'services/auth.api';
import { Product } from 'services/models';
import InfoItemsCard from '../components/common/InfoItemsCard/InfoItemsCard';

import star from '../assets/star.png';
import ing_1 from '../assets/img_igr_1.png';
import ing_2 from '../assets/img_igr_2.png';
import ing_3 from '../assets/img_igr_3.png';

import { OptimizedImage } from '../components/common/OptimizedImage';

const JoinFurFamily = loadable(() => import('components/JoinFurFamily'));
const Ingredients = loadable(() =>
  import('components/common/NutritionalInfo/Ingredients'),
);
const NutritionistSection = loadable(() =>
  import('components/common/NutritionistSection'),
);

const Testimonials = loadable(() =>
  import('components/Home/Testimonials/Testimonials'),
);

const infoItems = [
  {
    imgSrc: ing_1,
    imgAlt: 'ingredient1',
    imgTitle: 'ingredient1',
    title: 'High in Protein',
    description:
      'Chicken is the number 1 ingredient. Wild caught NZ fish and grass fed NZ beef help provide all the essential amino acids a thriving dog needs.',
  },
  {
    imgSrc: ing_2,
    imgAlt: 'ingredient2',
    imgTitle: 'ingredient2',
    title: 'High in Omega-3 & Omega-6 Fatty Acids',
    description:
      'Properly balanced Omega 3 and 6 are the key to reducing inflammation and allergies in dogs.',
  },
  {
    imgSrc: ing_3,
    imgAlt: 'ingredient3',
    imgTitle: 'ingredient3',
    title: 'Complete & Balanced Nutrition',
    description:
      'A single source diet that exceeds AAFCO requirements for a complete and balanced diet.Everything a modern dog needs to thrive.',
  },
];

export default (props: any) => {
  const { nodes } = props.data.allRestApiApiProducts;
  const cdbProduct = nodes.find(node => node.endpointId === 7);
  const nutritional_information =
    cdbProduct.nutritional_information?.nutritional_description;
  const renderFeedPuppySection = () => (
    <div className="teat-bg">
      <div
        className="section-item def-w-max"
        style={{ marginTop: 50, marginBottom: 50 }}
      >
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="txt-wrap-sect pdd-rt-50">
              <h2 className="mgn-bot-30">Feeding a Puppy</h2>
              <p className="para-txt mgn-bot-20">
                Our food is perfect for feeding a puppy. It's designed by
                leading kiwi veterinary nutritionists to meet international
                AAFCO standards (or greatly exceed minimums) for an ‘All Life
                Stages’ furbaby food, giving you confidence they’re getting all
                their nutritional needs from puppy through to old age.
              </p>
              <div className="tick-list">
                <ul>
                  <li>
                    Keep your dog on the same food from puppy through to
                    adulthood and just adjust the meal size.
                  </li>
                  <li>
                    Puppies develop rapidly during the first 12 months of their
                    lives and need all the right nutrients to set them up to
                    thrive.
                  </li>
                  <li>
                    For large breed puppies (likely to be more than 32kgs as an
                    adult) we recommend waiting until they are around 8-10
                    months old to move on to our food.{' '}
                    <span
                      className="txt-btn-or-1"
                      onClick={() => {
                        navigate('/support');
                      }}
                      style={{
                        marginTop: 5,
                        fontWeight: 500,
                        fontSize: 18,
                        cursor: 'pointer',
                        color: '#f36e47',
                      }}
                    >
                      {' '}
                      Get in touch{' '}
                    </span>
                    for advice
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="img-wrap-sect op-img-w-100">
              <OptimizedImage fileName="feed_puppy.jpeg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderDogAllergySection = () => (
    <div className="treats-3-bg">
      <div className="section-item def-w-max no-mgn">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="img-wrap-sect op-img-w-100">
              <OptimizedImage fileName="dog_allergy.jpg" />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="txt-wrap-sect">
              <h2 className="mgn-bot-30">Low Allergy Dog Food.</h2>
              <p className="para-txt mgn-bot-20">
                Feed My Furbaby is designed as a low allergy dog food and our
                unique natural recipe has helped loads of kiwi furbabies with
                skin and gut issues (check out our reviews). If you know
                allergies, like we do, you know they’re all different and we
                love nothing more than making dogs more comfortable. Please get
                in touch if we might be able to help your furbaby feel better.
              </p>
              <div className="tick-list">
                <ul>
                  <li>
                    All natural and made from quality locally sourced
                    ingredients.
                  </li>
                  <li>
                    Grain-free recipe with no artificial colours, flavours or
                    preservatives.
                  </li>
                  <li>
                    Ideal amino acid balance (critical for managing
                    inflammation).
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <MainLayout withTitle>
      <div className="banner ingr-banner">
        <OptimizedImage fileName="bg_banner_ingr.jpg" />
        {/* <img src={bg_banner_ingr}/> */}
        <div className="container-fluid">
          <div className="banner-content">
            <h5>The best grain free dog food in New Zealand.</h5>
            <h1>
              Your dog deserves the best
              <br />
              Ingredients in the World.
              <br />
              Local NZ Made and Natural
            </h1>
          </div>
        </div>
      </div>

      <div className="ingr-col-wrapper">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="ing-hdr-wrap text-center">
              <h2 className="mgn-bot-20">
                Our Goal: the healthiest your dog has ever been
              </h2>
              <p>
                Great dog food starts with great ingredients. But it’s how a
                great recipe is put together that makes the real difference.
              </p>
            </div>
            <div className="how-wrapper text-center">
              <div className="row">
                {infoItems.map((item, index) => (
                  <InfoItemsCard
                    key={index}
                    imgSrc={item.imgSrc}
                    imgAlt={item.imgAlt}
                    imgTitle={item.imgTitle}
                    title={item.title}
                    description={item.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="ingr-col-wrapper">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="ing-hdr-wrap text-center">
              <h2 className="mgn-bot-20">
                Our Goal: the healthiest your dog has ever been
              </h2>
              <p>
                Great dog food starts with great ingredients. But it’s how a
                great recipe is put together that makes the real difference.
              </p>
            </div>
            <div className="how-wrapper text-center">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <div className="img-txt-wrapper">
                    <div className="img-wrapper">
                      <img src={ing_1} />
                    </div>
                    <h4>High in Protein</h4>
                    <p>
                      Chicken is the number 1 ingredient. Wild caught NZ fish
                      and grass fed NZ beef help provide all the essential amino
                      acids a thriving dog needs.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="img-txt-wrapper">
                    <div className="img-wrapper">
                      <img src={ing_2} />
                    </div>
                    <h4>High in Omega-3 & Omega-6 Fatty Acids</h4>
                    <p>
                      Properly balanced Omega 3 and 6 are the key to reducing
                      inflammation and allergies in dogs.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="img-txt-wrapper">
                    <div className="img-wrapper">
                      <img src={ing_3} />
                    </div>
                    <h4>Complete & Balanced Nutrition</h4>
                    <p>
                      A single source diet that exceeds AAFCO requirements for a
                      complete and balanced diet.
                      <br />
                      Everything a modern dog needs to thrive.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="features-wrapper">
        <div className="container-fluid">
          <div className="treats-3-bg">
            <div className="section-item def-w-max no-mgn">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="op-img-wrap">
                    <OptimizedImage fileName="igr_img_st_1.png" />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="txt-wrap-sect">
                    <h2 className="mgn-bot-30">100% Natural Dog Food</h2>
                    <p className="para-txt mgn-bot-50">
                      Your dog doesn’t need all those nasty colours, flavours
                      and preservatives. <br />
                      He needs great natural ingredients in the right amounts.
                      It’s that simple.
                    </p>
                    <div className="tick-list">
                      <ul>
                        <li>
                          We source the best natural ingredients right here in
                          New Zealand, and make our great natural dog food
                          locally too.
                        </li>
                        <li>
                          All natural ingredients with added vitamins and
                          minerals to give them everything they need.
                        </li>
                        <li>
                          Grain free for a better quality of plant based
                          ingredients. We use peas, potatoes and casava root
                          instead of cheap fillers like wheat, corn, soy and
                          other grains.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="teat-bg">
            <div className="section-item def-w-max">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="txt-wrap-sect pdd-rt-50">
                    <h2 className="mgn-bot-30">What is it made from?</h2>
                    <p className="para-txt mgn-bot-50">
                      All the good stuff they need and nothing they don’t! Great
                      local ingredients made right here in New Zealand.
                    </p>
                    <div className="col-list-wrap">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <strong>Crude Protein</strong>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>min 28%</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <strong>Crude Fat</strong>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>min 14%</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <strong>Calcium</strong>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>2.5%</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <strong>Crude Fibre</strong>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>max 4%</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <strong>Moisture</strong>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>max 10%</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <strong>Phosphorus</strong>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>1.6%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix igr-btn-wrap mgn-top-30">
                      <Button
                        className="btn-def pull-left no-mgn"
                        onClick={() =>
                          navigate(
                            isLoggedIn()
                              ? '/account/dashboard/'
                              : '/getstarted/',
                          )
                        }
                      >
                        {isLoggedIn() ? 'Dashboard' : 'Get started now'}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="op-img-wrap">
                    <OptimizedImage fileName="igr_img_st_2.png" />
                  </div>
                </div>
                <div className="col-12">
                  <Ingredients
                    nutritional_information={nutritional_information}
                  />
                </div>
              </div>
            </div>
          </div>

          <NutritionistSection />
          {renderFeedPuppySection()}
          {renderDogAllergySection()}
        </div>
      </div>
      <Testimonials />
      <JoinFurFamily page_id="ingredients_page_footer" />
    </MainLayout>
  );
};
export const query = graphql`
  query {
    allRestApiApiProducts {
      nodes {
        title
        id
        endpointId
        image
        product_attributes {
          code
          name
          value
        }
        description
        nutritional_information {
          nutritional_description
          crude_protein
          crude_fat
          crude_fibre
          moisture
          calcium
          phosphorus
        }
      }
      totalCount
    }
  }
`;
