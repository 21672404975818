import React from 'react';
import './styles.scss';

interface InfoItemsCardProps {
  imgSrc: string;
  imgAlt: string;
  imgTitle: string;
  title: string;
  description: string;
}

const InfoItemsCard: React.FC<InfoItemsCardProps> = ({
  imgSrc,
  imgAlt,
  imgTitle,
  title,
  description,
}) => {
  return (
    <div className="col-12 col-sm-4">
      <div className="img-txt-wrapper card-shadow">
        <div className="img-wrapper">
          <img
            className="info-img"
            src={imgSrc}
            alt={imgAlt}
            title={imgTitle}
          />
        </div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default InfoItemsCard;
